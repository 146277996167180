import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../views/Alipay/index.vue')
    }
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: function () {
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/Alipay/index',
    name: 'Index',
    component: function () {
      return import('../views/Alipay/index.vue')
    }
  },
  {
    path: '/Alipay/index2',
    name: 'Index2',
    component: function () {
      return import('../views/Alipay/index2.vue')
    }
  },
  {
    path: '/Alipay/index2B',
    name: 'Index2B',
    component: function () {
      return import('../views/Alipay/index2B.vue')
    }
  },
  {
    path: '/Alipay/index3',
    name: 'Index3',
    component: function () {
      return import('../views/Alipay/index3.vue')
    }
  },
  {
    path: '/Alipay/index4',
    name: 'Index4',
    component: function () {
      return import('../views/Alipay/index4.vue')
    }
  },
  {
    path: '/Alipay/index5',
    name: 'Index5',
    component: function () {
      return import('../views/Alipay/index5.vue')
    }
  },
  {
    path: '/Alipay/index5A',
    name: 'Index5A',
    component: function () {
      return import('../views/Alipay/index5A.vue')
    }
  },
  {
    path: '/Alipay/index5B',
    name: 'Index5B',
    component: function () {
      return import('../views/Alipay/index5B.vue')
    }
  },
  {
    path: '/Alipay/index5C',
    name: 'Index5C',
    component: function () {
      return import('../views/Alipay/index5C.vue')
    }
  },
  {
    path: '/Alipay/index6',
    name: 'Index6',
    component: function () {
      return import('../views/Alipay/index6.vue')
    }
  },
  {
    path: '/Alipay/index7',
    name: 'Index7',
    component: function () {
      return import('../views/Alipay/index7.vue')
    }
  },
  {
    path: '/Alipay/index8',
    name: 'Index8',
    component: function () {
      return import('../views/Alipay/index8.vue')
    }
  },
  {
    path: '/Alipay/index9',
    name: 'Index9',
    component: function () {
      return import('../views/Alipay/index9.vue')
    }
  },
  {
    path: '/Alipay/index10',
    name: 'Index10',
    component: function () {
      return import('../views/Alipay/index10.vue')
    }
  },
  {
    path: '/Alipay/index11',
    name: 'Index11',
    component: function () {
      return import('../views/Alipay/index11.vue')
    }
  },
  {
    path: '/Alipay/index12',
    name: 'Index12',
    component: function () {
      return import('../views/Alipay/index12.vue')
    }
  },
  {
    path: '/Wx/index',
    name: '/Wx/index',
    component: function () {
      return import('../views/Wx/index.vue')
    }
  },
  {
    path: '/Wx/indexB',
    name: '/Wx/indexB',
    component: function () {
      return import('../views/Wx/indexB.vue')
    }
  },
  {
    path: '/Wx/index2',
    name: '/Wx/index2',
    component: function () {
      return import('../views/Wx/index2.vue')
    }
  },
  {
    path: '/Wx/index2B',
    name: '/Wx/index2B',
    component: function () {
      return import('../views/Wx/index2B.vue')
    }
  },
  {
    path: '/Wx/index2C',
    name: '/Wx/index2C',
    component: function () {
      return import('../views/Wx/index2C.vue')
    }
  },
  {
    path: '/Wx/index2D',
    name: '/Wx/index2D',
    component: function () {
      return import('../views/Wx/index2D.vue')
    }
  },
  {
    path: '/Wx/index2E',
    name: '/Wx/index2E',
    component: function () {
      return import('../views/Wx/index2E.vue')
    }
  },
  {
    path: '/Wx/index2Etest',
    name: '/Wx/index2Etest',
    component: function () {
      return import('../views/Wx/index2Etest.vue')
    }
  },
  {
    path: '/Wx/index2F',
    name: '/Wx/index2F',
    component: function () {
      return import('../views/Wx/index2F.vue')
    }
  },
  {
    path: '/Wx/index3',
    name: '/Wx/index3',
    component: function () {
      return import('../views/Wx/index3.vue')
    }
  },
  {
    path: '/Wx/index4',
    name: '/Wx/index4',
    component: function () {
      return import('../views/Wx/index4.vue')
    }
  },
  {
    path: '/Wx/index5',
    name: '/Wx/index5',
    component: function () {
      return import('../views/Wx/index5.vue')
    }
  },
  {
    path: '/Wx/index6',
    name: '/Wx/index6',
    component: function () {
      return import('../views/Wx/index6.vue')
    }
  },
  {
    path: '/Wx/index7',
    name: '/Wx/index7',
    component: function () {
      return import('../views/Wx/index7.vue')
    }
  },
  {
    path: '/Wx/index7A',
    name: '/Wx/index7A',
    component: function () {
      return import('../views/Wx/index7A.vue')
    }
  },
  {
    path: '/Wx/index7B',
    name: '/Wx/index7B',
    component: function () {
      return import('../views/Wx/index7B.vue')
    }
  },
  {
    path: '/Wx/index7C',
    name: '/Wx/index7C',
    component: function () {
      return import('../views/Wx/index7C.vue')
    }
  },
  {
    path: '/Wx/index7D',
    name: '/Wx/index7D',
    component: function () {
      return import('../views/Wx/index7D.vue')
    }
  },
  {
    path: '/Wx/index7E',
    name: '/Wx/index7E',
    component: function () {
      return import('../views/Wx/index7E.vue')
    }
  },
  {
    path: '/Wx/index7F',
    name: '/Wx/index7F',
    component: function () {
      return import('../views/Wx/index7F.vue')
    }
  },
  {
    path: '/Wx/index7G',
    name: '/Wx/index7G',
    component: function () {
      return import('../views/Wx/index7G.vue')
    }
  },
  {
    path: '/Wx/index7H',
    name: '/Wx/index7H',
    component: function () {
      return import('../views/Wx/index7H.vue')
    }
  },
  {
    path: '/Wx/index8',
    name: '/Wx/index8',
    component: function () {
      return import('../views/Wx/index8.vue')
    }
  },
  {
    path: '/Wx/index9',
    name: '/Wx/index9',
    component: function () {
      return import('../views/Wx/index9.vue')
    }
  },
  {
    path: '/Wx/index10',
    name: '/Wx/index10',
    component: function () {
      return import('../views/Wx/index10.vue')
    }
  },
  {
    path: '/Wx/index11',
    name: '/Wx/index11',
    component: function () {
      return import('../views/Wx/index11.vue')
    }
  },
  {
    path: '/Wx/index12',
    name: '/Wx/index12',
    component: function () {
      return import('../views/Wx/index12.vue')
    }
  },
  {
    path: '/Wx/index13',
    name: '/Wx/index13',
    component: function () {
      return import('../views/Wx/index13.vue')
    }
  },
  {
    path: '/Wx/index14',
    name: '/Wx/index14',
    component: function () {
      return import('../views/Wx/index14.vue')
    }
  },
  {
    path: '/Wx/success',
    name: '/Wx/success',
    component: function () {
      return import('../views/Wx/success.vue')
    }
  },
  {
    path: '/Lt/index',
    name: '/Lt/index',
    component: function () {
      return import('../views/Lt/index.vue')
    }
  },
  {
    path: '/Lt/indexB',
    name: '/Lt/indexB',
    component: function () {
      return import('../views/Lt/indexB.vue')
    }
  },
  {
    path: '/Lt/indexC',
    name: '/Lt/indexC',
    component: function () {
      return import('../views/Lt/indexC.vue')
    }
  },
  {
    path: '/Lt/indexD',
    name: '/Lt/indexD',
    component: function () {
      return import('../views/Lt/indexD.vue')
    }
  },
  {
    path: '/Lt/',
    name: '/Lt/index2',
    component: function () {
      return import('../views/Lt/index2.vue')
    }
  },
  {
    path: '/Ltnew/',
    name: '/Lt/index3',
    component: function () {
      return import('../views/Lt/index3.vue')
    }
  },
  {
    path: '/Lt/index4',
    name: '/Lt/index4',
    component: function () {
      return import('../views/Lt/index4.vue')
    }
  },
  {
    path: '/Lt/index5',
    name: '/Lt/index5',
    component: function () {
      return import('../views/Lt/index5.vue')
    }
  },
  {
    path: '/Lt/index6',
    name: '/Lt/index6',
    component: function () {
      return import('../views/Lt/index6.vue')
    }
  },
  {
    path: '/Ltnew/index6',
    name: '/Ltnew/index6',
    component: function () {
      return import('../views/Lt/index6B.vue')
    }
  },
  {
    path: '/Lt/index7',
    name: '/Lt/index7',
    component: function () {
      return import('../views/Lt/index7.vue')
    }
  },
  {
    path: '/Lt/index8',
    name: '/Lt/index8',
    component: function () {
      return import('../views/Lt/index8.vue')
    }
  },
  {
    path: '/Ltnew/index9',
    name: '/Ltnew/index9',
    component: function () {
      return import('../views/Lt/index9.vue')
    }
  },
  {
    path: '/Lt/index10',
    name: '/Lt/index10',
    component: function () {
      return import('../views/Lt/index10.vue')
    }
  },
  {
    path: '/Lt/index11',
    name: '/Lt/index11',
    component: function () {
      return import('../views/Lt/index11.vue')
    }
  },
  {
    path: '/Lt/index12',
    name: '/Lt/index12',
    component: function () {
      return import('../views/Lt/index12.vue')
    }
  },
  {
    path: '/LtZc/',
    name: '/LtZc/',
    component: function () {
      return import('../views/LtZc/index.vue')
    }
  },
  {
    path: '/LtZc/index2',
    name: '/LtZc/index2',
    component: function () {
      return import('../views/LtZc/index2.vue')
    }
  },
  {
    path: '/LtZc/index3',
    name: '/LtZc/index3',
    component: function () {
      return import('../views/LtZc/index3.vue')
    }
  },
  {
    path: '/LtZc/index4',
    name: '/LtZc/index4',
    component: function () {
      return import('../views/LtZc/index4.vue')
    }
  },
  {
    path: '/LtZc/index5',
    name: '/LtZc/index5',
    component: function () {
      return import('../views/LtZc/index5.vue')
    }
  },
  {
    path: '/LtZc/index6',
    name: '/LtZc/index6',
    component: function () {
      return import('../views/LtZc/index6.vue')
    }
  },
  {
    path: '/LtZc/index7',
    name: '/LtZc/index7',
    component: function () {
      return import('../views/LtZc/index7.vue')
    }
  },
  {
    path: '/LtTy/',
    name: '/LtTy/',
    component: function () {
      return import('../views/LtTy/index.vue')
    }
  },
  {
    path: '/LtTy/index2',
    name: '/LtTy/index2',
    component: function () {
      return import('../views/LtTy/index2.vue')
    }
  },
  {
    path: '/LtTy/index3',
    name: '/LtTy/index3',
    component: function () {
      return import('../views/LtTy/index3.vue')
    }
  },
  {
    path: '/LtTy/index4',
    name: '/LtTy/index4',
    component: function () {
      return import('../views/LtTy/index4.vue')
    }
  },
  {
    path: '/Yd/index',
    name: '/Yd/index',
    component: function () {
      return import('../views/Yd/index.vue')
    }
  },
  {
    path: '/Yd/index2',
    name: '/Yd/index2',
    component: function () {
      return import('../views/Yd/index2.vue')
    }
  },
  {
    path: '/Yd/index3',
    name: '/Yd/index3',
    component: function () {
      return import('../views/Yd/index3.vue')
    }
  },
  {
    path: '/Yd/index4',
    name: '/Yd/index4',
    component: function () {
      return import('../views/Yd/index4.vue')
    }
  },
  {
    path: '/Yd/index4B',
    name: '/Yd/index4B',
    component: function () {
      return import('../views/Yd/index4B.vue')
    }
  },
  {
    path: '/Yd/index4C',
    name: '/Yd/index4C',
    component: function () {
      return import('../views/Yd/index4C.vue')
    }
  },
  {
    path: '/Yd/index5',
    name: '/Yd/index5',
    component: function () {
      return import('../views/Yd/index5.vue')
    }
  },
  {
    path: '/Yd/index6',
    name: '/Yd/index6',
    component: function () {
      return import('../views/Yd/index6.vue')
    }
  },
  {
    path: '/Yd/index6B',
    name: '/Yd/index6B',
    component: function () {
      return import('../views/Yd/index6B.vue')
    }
  },
  {
    path: '/Yd/index7',
    name: '/Yd/index7',
    component: function () {
      return import('../views/Yd/index7.vue')
    }
  },
  {
    path: '/Yd/index8',
    name: '/Yd/index8',
    component: function () {
      return import('../views/Yd/index8.vue')
    }
  },
  {
    path: '/Yd/index8B',
    name: '/Yd/index8B',
    component: function () {
      return import('../views/Yd/index8B.vue')
    }
  },
  {
    path: '/Yd/index8C',
    name: '/Yd/index8C',
    component: function () {
      return import('../views/Yd/index8C.vue')
    }
  },
  {
    path: '/Yd/index8D',
    name: '/Yd/index8D',
    component: function () {
      return import('../views/Yd/index8D.vue')
    }
  },
  {
    path: '/Yd/index9',
    name: '/Yd/index9',
    component: function () {
      return import('../views/Yd/index9.vue')
    }
  },
  {
    path: '/Yd/index10',
    name: '/Yd/index10',
    component: function () {
      return import('../views/Yd/index10.vue')
    }
  },
  {
    path: '/Yd/index11',
    name: '/Yd/index11',
    component: function () {
      return import('../views/Yd/index11.vue')
    }
  },
  {
    path: '/Yd/index12',
    name: '/Yd/index12',
    component: function () {
      return import('../views/Yd/index12.vue')
    }
  },
  {
    path: '/Yd/index12B',
    name: '/Yd/index12B',
    component: function () {
      return import('../views/Yd/index12B.vue')
    }
  },
  {
    path: '/Yd/index12C',
    name: '/Yd/index12C',
    component: function () {
      return import('../views/Yd/index12C.vue')
    }
  },
  {
    path: '/Yd/index12D',
    name: '/Yd/index12D',
    component: function () {
      return import('../views/Yd/index12D.vue')
    }
  },
  {
    path: '/Yd/index13',
    name: '/Yd/index13',
    component: function () {
      return import('../views/Yd/index13.vue')
    }
  },
  {
    path: '/Yd/index13B',
    name: '/Yd/index13B',
    component: function () {
      return import('../views/Yd/index13B.vue')
    }
  },
  {
    path: '/Yd/index13C',
    name: '/Yd/index13C',
    component: function () {
      return import('../views/Yd/index13C.vue')
    }
  },
  {
    path: '/Yd/index13D',
    name: '/Yd/index13D',
    component: function () {
      return import('../views/Yd/index13D.vue')
    }
  },
  {
    path: '/Yd/index13E',
    name: '/Yd/index13E',
    component: function () {
      return import('../views/Yd/index13E.vue')
    }
  },
  {
    path: '/Yd/index14',
    name: '/Yd/index14',
    component: function () {
      return import('../views/Yd/index14.vue')
    }
  },
  {
    path: '/Yd/index15',
    name: '/Yd/index15',
    component: function () {
      return import('../views/Yd/index15.vue')
    }
  },
  {
    path: '/Yd/index16',
    name: '/Yd/index16',
    component: function () {
      return import('../views/Yd/index16.vue')
    }
  },
  {
    path: '/Yd/index16B',
    name: '/Yd/index16B',
    component: function () {
      return import('../views/Yd/index16B.vue')
    }
  },
  {
    path: '/Yd/index16C',
    name: '/Yd/index16C',
    component: function () {
      return import('../views/Yd/index16C.vue')
    }
  },
  {
    path: '/Yd/index17',
    name: '/Yd/index17',
    component: function () {
      return import('../views/Yd/index17.vue')
    }
  },
  {
    path: '/Yd/index18',
    name: '/Yd/index18',
    component: function () {
      return import('../views/Yd/index18.vue')
    }
  },
  {
    path: '/Yd/index19',
    name: '/Yd/index19',
    component: function () {
      return import('../views/Yd/index19.vue')
    }
  },
  {
    path: '/Yd/index20',
    name: '/Yd/index20',
    component: function () {
      return import('../views/Yd/index20.vue')
    }
  },
 
  {
    path: '/Yd/index3B',
    name: '/Yd/index3B',
    component: function () {
      return import('../views/Yd/index3B.vue')
    }
  },
  {
    path: '/Yd/HomeView',
    name: '/Yd/HomeView',
    component: function () {
      return import('../views/Yd/HomeView.vue')
    }
  },
  {
    path: '/jf/index',
    name: '/jf/index',
    component: function () {
      return import('../views/jf/index.vue')
    }
  },
  {
    path: '/Dx/index',
    name: '/Dx/index',
    component: function () {
      return import('../views/Dx/index.vue')
    }
  },
  {
    path: '/Dx/index2',
    name: '/Dx/index2',
    component: function () {
      return import('../views/Dx/index2.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV == "production" ? '/unifiedActivty/activty' : '/',
  // base: process.env.NODE_ENV == "production" ? '/active' : '/',
  routes
})

export default router
